@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
//@import url(https://atoms.azureedge.net/atoms/assets/fonts/roboto.css);
//@import url(https://atoms.azureedge.net/atoms/lib/latest/core.css);

// Color scheme alternative:
// @import url(https://atoms.azureedge.net/atoms/lib/latest/themes/light-green.css);
// @import url(https://atoms.azureedge.net/atoms/themes/dark-green.css);
// @import url(https://atoms.azureedge.net/atoms/themes/light-blue.css);
// @import url(https://atoms.azureedge.net/atoms/themes/dark-blue.css);

// Roboto alternative, if atoms fails to load:
// @import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap);

// Overrides
atom-radio-button,
atom-checkbox {
  label {
    cursor: pointer;
    user-select: none;
  }
}

atom-tooltip:host .tooltip {
  z-index: 1;
}
