/* evergreen sanitize css
 see https://github.com/csstools/sanitize.css/blob/master/evergreen.css
 */
/* Document
 * ========================================================================== */

/**
 * 1. Add border box sizing in all browsers (opinionated).
 * 2. Backgrounds do not repeat by default (opinionated).
 */

*,
::before,
::after {
    box-sizing: border-box; /* 1 */
    background-repeat: no-repeat; /* 2 */
}

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */

::before,
::after {
    text-decoration: inherit; /* 1 */
    vertical-align: inherit; /* 2 */
}

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Change the line height in all browsers (opinionated).
 * 3. Breaks words to prevent overflow in all browsers (opinionated).
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Remove the grey highlight on links in iOS (opinionated).
 * 6. Prevent adjustments of font size after orientation changes in iOS.
 */

html {
    cursor: default; /* 1 */
    line-height: 1.5; /* 2 */
    overflow-wrap: anywhere; /* 3 */
    -moz-tab-size: 4; /* 4 */
    tab-size: 4; /* 4 */
    -webkit-tap-highlight-color: transparent /* 5 */;
    -webkit-text-size-adjust: 100%; /* 6 */
}

/* Sections
 * ========================================================================== */

/**
 * Remove the margin in all browsers (opinionated).
 */

body {
    margin: 0;
}

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
    margin: 0;
}

/**
 * 1. Correct the inheritance of border color in Firefox.
 * 2. Add the correct box sizing in Firefox.
 */

hr {
    color: inherit; /* 1 */
    height: 0; /* 2 */
}

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */

nav ol,
nav ul {
    list-style: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'/%3E");
    padding: 0;
}

/**
 * Prevent VoiceOver from ignoring list semantics in Safari (opinionated).
 */

nav li::before {
    content: "\200B";
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 * 3. Prevent overflow of the container in all browsers (opinionated).
 */

pre {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
    overflow: auto; /* 3 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Safari.
 */

abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
    font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
    font-family: monospace, monospace; /* 1 */
    font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
    font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/*
 * Change the alignment on media elements in all browsers (opinionated).
 */

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
    display: none;
    height: 0;
}

/**
 * Remove the border on iframes in all browsers (opinionated).
 */

iframe {
    border-style: none;
}

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */

svg:not([fill]) {
    fill: currentColor;
}

/* Tabular data
 * ========================================================================== */

/**
 * 1. Collapse border spacing in all browsers (opinionated).
 * 2. Correct table border color inheritance in all Chrome, Edge, and Safari.
 * 3. Remove text indentation from table contents in Chrome, Edge, and Safari.
 */

table {
    border-collapse: collapse; /* 1 */
    border-color: inherit; /* 2 */
    text-indent: 0; /* 3 */
}

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
    margin: 0;
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

button {
    text-transform: none;
}

/**
 * 1. Change the inconsistent appearance in all browsers (opinionated).
 * 2. Correct the padding in Firefox.
 */

fieldset {
    border: 1px solid #a0a0a0; /* 1 */
    padding: 0.35em 0.75em 0.625em; /* 2 */
}

/**
 * Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
    vertical-align: baseline;
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
    text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 3. Change the resize direction in all browsers (opinionated).
 */

textarea {
    margin: 0; /* 1 */
    resize: vertical; /* 3 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search" i] {
    -webkit-appearance: textfield; /* 1 */
    outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
    -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
    -webkit-appearance: button; /* 1 */
    font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
    outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
    box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct styles in Safari.
 */

dialog {
    background-color: white;
    border: solid;
    color: black;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    left: 0;
    margin: auto;
    padding: 1em;
    position: absolute;
    right: 0;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
}

dialog:not([open]) {
    display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
    display: list-item;
}

/* Accessibility
 * ========================================================================== */

/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */

[aria-busy="true" i] {
    cursor: progress;
}

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */

:not(input)[aria-controls] {
    cursor: pointer;
}

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */

[aria-disabled="true" i],
[disabled] {
    cursor: not-allowed;
}

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */

[aria-hidden="false" i][hidden] {
    display: initial;
}

[aria-hidden="false" i][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    position: absolute;
}


/************************************/
/*****         CORE            ******/
:root {
    /* BASF Dark Blue */
    --atoms-basf-blue-dark-025: #004A961A; /* rgba(0, 74, 150, 0.1) */
    --atoms-basf-blue-dark-050: #E5EDF4; /* rgb(229, 237, 244) */
    --atoms-basf-blue-dark-075: #D9E4EF; /* rgb(217, 228, 239) */
    --atoms-basf-blue-dark-100: #CCDBEA; /* rgb(204, 219, 234) */
    --atoms-basf-blue-dark-200: #99B7D5; /* rgb(153, 183, 213) */
    --atoms-basf-blue-dark-300: #6692C0; /* rgb(102, 146, 192) */
    --atoms-basf-blue-dark-400: #336EAB; /* rgb(51, 110, 171) */
    --atoms-basf-blue-dark-425: #2665A6; /* rgb(38, 101, 166) */
    --atoms-basf-blue-dark-500: #004A96; /* rgb(0, 74, 150) */
    --atoms-basf-blue-dark-600: #003B78; /* rgb(0, 59, 120) */
    --atoms-basf-blue-dark-700: #002C5A; /* rgb(0, 44, 90) */
    --atoms-basf-blue-dark-800: #001E3C; /* rgb(0, 30, 60) */
    --atoms-basf-blue-dark-900: #000F1E; /* rgb(0, 15, 30) */

    /* BASF Light Blue */
    --atoms-basf-blue-light-025: #21A0D21A; /* rgba(33, 160, 210, 0.1) */
    --atoms-basf-blue-light-050: #E9F5FA; /* rgb(233, 245, 250) */
    --atoms-basf-blue-light-075: #DEF1F8; /* rgb(222, 241, 248) */
    --atoms-basf-blue-light-100: #D3ECF6; /* rgb(211, 236, 246) */
    --atoms-basf-blue-light-200: #A6D9ED; /* rgb(166, 217, 237) */
    --atoms-basf-blue-light-300: #7AC6E4; /* rgb(122, 198, 228) */
    --atoms-basf-blue-light-375: #59B8DE; /* rgb(89, 184, 222) */
    --atoms-basf-blue-light-400: #4DB3DB; /* rgb(77, 179, 219) */
    --atoms-basf-blue-light-500: #21A0D2; /* rgb(33, 160, 210) */
    --atoms-basf-blue-light-600: #1A80A8; /* rgb(26, 128, 168) */
    --atoms-basf-blue-light-700: #14607E; /* rgb(20, 96, 126) */
    --atoms-basf-blue-light-800: #0D4054; /* rgb(13, 64, 84) */
    --atoms-basf-blue-light-900: #000F1E; /* rgb(0, 15, 30) */

    /* BASF Light green */
    --atoms-basf-green-light-025: #65AC1E1A; /* rgba(101, 172, 30, 0.1) */
    --atoms-basf-green-light-050: #F0F7E8; /* rgb(240, 247, 232) */
    --atoms-basf-green-light-100: #E0EED2; /* rgb(224, 238, 210) */
    --atoms-basf-green-light-200: #C1DEA5; /* rgb(193, 222, 165) */
    --atoms-basf-green-light-300: #A3CD78; /* rgb(163, 205, 120) */
    --atoms-basf-green-light-375: #8CC156; /* rgb(140, 193, 86); */
    --atoms-basf-green-light-400: #84BD4B; /* rgb(132, 189, 75) */
    --atoms-basf-green-light-500: #65AC1E; /* rgb(101, 172, 30) */
    --atoms-basf-green-light-600: #518A18; /* rgb(81, 138, 24) */
    --atoms-basf-green-light-700: #3D6712; /* rgb(61, 103, 18) */
    --atoms-basf-green-light-800: #28450C; /* rgb(40, 69, 12) */
    --atoms-basf-green-light-900: #142206; /* rgb(20, 34, 6) */

    /* BASF Dark green */
    --atoms-basf-green-dark-025: #00793A1A; /* rgba(0, 121, 58, 0.1) */
    --atoms-basf-green-dark-050: #E5F2EB; /* rgb(229, 242, 235) */
    --atoms-basf-green-dark-075: #D9EBE1; /* rgb(217, 235, 225) */
    --atoms-basf-green-dark-100: #CCE4D8; /* rgb(204, 228, 216) */
    --atoms-basf-green-dark-200: #99C9B0; /* rgb(153, 201, 176) */
    --atoms-basf-green-dark-300: #66AF89; /* rgb(102, 175, 137) */
    --atoms-basf-green-dark-375: #66AF89; /* rgb(64, 154, 107) */
    --atoms-basf-green-dark-400: #339461; /* rgb(51, 148, 97) */
    --atoms-basf-green-dark-500: #00793A; /* rgb(0, 121, 58) */
    --atoms-basf-green-dark-600: #00612E; /* rgb(0, 97, 46) */
    --atoms-basf-green-dark-700: #004923; /* rgb(0, 73, 35) */
    --atoms-basf-green-dark-800: #003017; /* rgb(0, 48, 23) */
    --atoms-basf-green-dark-900: #00180C; /* rgb(0, 24, 12) */

    /* BASF Orange */
    --atoms-basf-orange-025: #F395001A; /* rgba(243, 149, 0, 0.1) */
    --atoms-basf-orange-050: #FEF4E5; /* rgb(254, 244, 229) */
    --atoms-basf-orange-075: #FDEFD9; /* rgb(253, 239, 217) */
    --atoms-basf-orange-100: #FDEACC; /* rgb(253, 234, 204) */
    --atoms-basf-orange-200: #FAD599; /* rgb(250, 213, 153) */
    --atoms-basf-orange-300: #F8BF66; /* rgb(248, 191, 102) */
    --atoms-basf-orange-375: #F6AF40; /* rgb(246, 175, 64) */
    --atoms-basf-orange-400: #F5AA33; /* rgb(245, 170, 51) */
    --atoms-basf-orange-500: #F39500; /* rgb(243, 149, 0) */
    --atoms-basf-orange-600: #C27700; /* rgb(194, 119, 0) */
    --atoms-basf-orange-700: #925900; /* rgb(146, 89, 0) */
    --atoms-basf-orange-800: #613C00; /* rgb(97, 60, 0) */
    --atoms-basf-orange-900: #311E00; /* rgb(49, 30, 0) */

    /* BASF Red */
    --atoms-basf-red-025: #C500221A; /* rgba(197, 0, 34, 0.1) */
    --atoms-basf-red-050: #F9E5E9; /* rgb(249, 229, 233); */
    --atoms-basf-red-075: #F6D9DE; /* rgb(246, 217, 222); */
    --atoms-basf-red-100: #F3CCD3; /* rgb(243, 204, 211); */
    --atoms-basf-red-200: #E899A7; /* rgb(232, 153, 167); */
    --atoms-basf-red-300: #DC667A; /* rgb(220, 102, 122); */
    --atoms-basf-red-375: #D34059; /* rgb(211, 64, 89); */
    --atoms-basf-red-400: #D1334E; /* rgb(209, 51, 78); */
    --atoms-basf-red-500: #C50022; /* rgb(197, 0, 34); */
    --atoms-basf-red-600: #9E001B; /* rgb(158, 0, 27); */
    --atoms-basf-red-700: #760014; /* rgb(118, 0, 20); */
    --atoms-basf-red-800: #4F000E; /* rgb(79, 0, 14); */
    --atoms-basf-red-900: #270007; /* rgb(39, 0, 7); */

    /* BASF gray */
    --atoms-basf-gray-025: #3737371A; /* rgba(55, 55, 55, 0.1) */
    --atoms-basf-gray-050: #F5F5F5; /* rgb(245, 245, 245) */
    --atoms-basf-gray-075: #F0F0F0;
    --atoms-basf-gray-100: #ECECEC;
    --atoms-basf-gray-175: #DDDDDD;
    --atoms-basf-gray-200: #D8D8D8;
    --atoms-basf-gray-300: #C5C5C5;
    --atoms-basf-gray-400: #B1B1B1;
    --atoms-basf-gray-425: #ADADAD;
    --atoms-basf-gray-500: #9E9E9E;
    --atoms-basf-gray-600: #7E7E7E;
    --atoms-basf-gray-700: #5F5F5F;
    --atoms-basf-gray-800: #3F3F3F;
    --atoms-basf-gray-825: #373737;
    --atoms-basf-gray-900: #202020;

    --atoms-warn-color: var(--atoms-basf-red-500);

    --atoms-white: #ffffff;
    --atoms-box-gray: var(--atoms-basf-gray-075);
    --atoms-light-gray: var(--atoms-basf-gray-175);
    --atoms-middle-gray: var(--atoms-basf-gray-425);
    --atoms-black: var(--atoms-basf-gray-900);

    --atoms-basf-body-background: #E5E5E5;

    /* atoms Header */
    --atoms-header-left-column-width: calc(79% - .8125rem);
    --atoms-header-right-column-width: 21%;
    --atoms-header-right-column-columngap: .8125rem;

    font-family: 'Roboto', sans-serif;

    /* atoms Navigation */
    --atom-navigation-width:  18.5rem;
    --atom-navigation-icon-width: 4rem;

    /* Prefix and suffix icon size */
    --atoms-prefix-sufix-icon-size: 1.5rem;
}

/************************************/

body {
   background-color: var(--atoms-basf-body-background);
}

a:not(.no-restyle) {
    text-decoration: none;
    color: var(--atoms-primary-color);
}

a:hover {
    text-decoration: underline;
}

:root {
    --atoms-side-space: 216px;
    --atoms-screen-width: calc(100vw - (2 * var(--atoms-side-space)));
    --atoms-header-height: 6rem;

    /* atoms Breakpoints */
    --atoms-breakpoint-small: 672px;
    --atoms-breakpoint-medium: 1056px;
    --atoms-breakpoint-large: 1312px;
    --atoms-breakpoint-x-large: 1584px;
}

/* --- paragraphs --- */

p{
    font-size: 1rem; /* 16px */
    line-height: 1.5rem; /* 24px */
}

/* --- Headings --- */

h1{
    font-weight: 700;
}
h2{
    font-weight: 400;
}
h3{
    font-weight: 400;
}
h4{
    font-weight: 700;
}
h5{
    font-weight: 700;
}
h6{
    font-weight: 700;
}

/* --- above 1584  (Max)--- */
@media only screen and (min-width: 1584px) {

}

/* --- width 1312 till 1583 (Xlarge) --- */
@media only screen and (max-width: 1584px) {
    :root {
        --atoms-side-space: 2rem;
        --atom-navigation-width:  15.3125rem;
    }
}

/* --- width 1056 till 1311 (Large) --- */
@media only screen and (max-width: 1312px) {
    :root {
        --atoms-header-height: 5rem;
    }
}

/* --- width 1056 till 1311 (Large and above) --- */
@media only screen and (min-width: 1056px) {
    h1{
        font-size: 2.625rem; /* 42px */
        line-height: 3.125rem; /* 50px */
    }
    h2{
        font-size: 2.625rem; /* 42px */
        line-height: 3.125rem; /* 50px */
    }
    h3{
        font-size: 2.25rem; /* 36px */
        line-height: 2.75rem; /* 44px */
    }
    h4{
        font-size: 1.5rem; /* 24px */
        line-height: 2rem; /* 32px */
    }
    h5{
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem;  /* 28px */
    }
    h6{
        font-size: 1.125rem; /* 18px */
        line-height: 1.625rem; /* 26px */
    }
}

/* --- width 321 till 1056 (Small - Medium)  --- */
@media only screen and (max-width: 1056px) {
    :root {
        --atoms-side-space: 1.5rem;
        --atom-navigation-width:  16rem;
    }

    h1{
        font-size: 2rem; /* 32px */
        line-height: 2.5rem; /* 40px */
    }
    h2{
        font-size: 2rem; /* 32px */
        line-height: 2.5rem; /* 40px */
    }
    h3{
        font-size: 1.75rem; /* 28px */
        line-height: 2.25rem; /* 36px */
    }
    h4{
        font-size: 1.25rem; /* 20px */
        line-height: 1.75rem; /* 28px */
    }
    h5{
        font-size: 1rem; /* 16px */
        line-height: 1.5rem;  /* 24px */
    }
    h6{
        font-size: .875rem; /* 14px */
        line-height: 1.25rem; /* 20px */
    }
}

/* --- width 321 till 671 (Small)  --- */
@media only screen and (max-width: 672px) {
    :root {
        --atoms-side-space: 1rem;
    }
}
