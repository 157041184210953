:root {
    --atoms-primary-color: var(--atoms-basf-blue-dark-500);
    --atoms-secondary-color: var(--atoms-basf-blue-dark-425);
    --atoms-pale-color: var(--atoms-basf-blue-dark-075);
    --atoms-hover-color: var(--atoms-basf-blue-dark-600);
    --atoms-shadow-color: var(--atoms-basf-blue-dark-025);
    --atoms-basf-color-050: var(--atoms-basf-blue-dark-050);
    --atoms-basf-color-100: var(--atoms-basf-blue-dark-100);
    --atoms-basf-color-200: var(--atoms-basf-blue-dark-200);
    --atoms-basf-color-300: var(--atoms-basf-blue-dark-300);
    --atoms-basf-color-400: var(--atoms-basf-blue-dark-400);
    --atoms-basf-color-500: var(--atoms-basf-blue-dark-500);
    --atoms-basf-color-600: var(--atoms-basf-blue-dark-600);
    --atoms-basf-color-700: var(--atoms-basf-blue-dark-700);
    --atoms-basf-color-800: var(--atoms-basf-blue-dark-800);
    --atoms-basf-color-900: var(--atoms-basf-blue-dark-900);
}
